<template>
  <div class="modal-menu">
    <!-- prettier-ignore -->
    <div class="modal fade" id="sucursalInfoModal"
      aria-labelledby="modalMenu" tabindex="-1">
      <div class="modal-dialog modal-dialog-scrollable my-0">
        <div class="modal-content">
          <div class="modal-header p-2">
            <div class="modal-title d-flex">
              <h5 class="subtitle ms-1">{{sucursal.name}}</h5>
            </div>
            <button class="icon-cancel p-0" type="button"
              data-bs-dismiss="modal" aria-label="Close" 
              ref="closeMenu"></button>
            <button type="button" class="btn position-absolute p-0" style="right: 16px"
              data-bs-dismiss="modal" aria-label="Close">
                <span class="material-symbols-outlined">cancel</span>
            </button>
          </div>  <!-- End modal-header -->

          <div class="modal-body px-2 py-0">
            <div v-if="sucursal.address">
              <p class="fs-2 fw-bold">Dirección</p>
              <a class="d-flex align-items-center" target="_blank" :href="sucursal.address? `https://www.google.com/maps/search/${sucursal.address.replace(/ /g, '+')}` : ''">
                <span class="material-symbols-outlined me-1">location_on</span>
                {{ sucursal.address }}
              </a>
            </div>
            
            <div class="mt-2" v-if="sucursal.phone">
              <p class="fs-2 fw-bold">Teléfono</p>
              <a class="d-flex align-items-center" target="_blank" :href="`tel:${sucursal.phone}`">
                <span class="material-symbols-outlined me-1">call</span>
                {{ sucursal.phone }}
              </a>
            </div>

            <div class="mt-2" v-if="hasSomeNetwork">
              <p class="fs-2 fw-bold">Contacto</p>
              <div class="d-flex">
                <a class="d-flex align-items-center h1 me-2" target="_blank" v-if="sucursal.networks.whatsapp" :href="sucursal.networks.whatsapp">
                  <span class="icon-whatsapp"></span>
                </a>
                <a class="d-flex align-items-center h1 me-2" target="_blank" v-if="sucursal.networks.facebook" :href="sucursal.networks.facebook">
                  <span class="icon-facebook"></span>
                </a>
                <a class="d-flex align-items-center h1 me-2" target="_blank" v-if="sucursal.networks.instagram" :href="sucursal.networks.instagram">
                  <span class="icon-instagram"></span>
                </a>
                <a class="d-flex align-items-center h1 me-2" target="_blank" v-if="sucursal.networks.tiktok" :href="sucursal.networks.tiktok">
                  <span class="icon-tiktok"></span>
                </a>
              </div>
            </div>
          </div> <!-- End modal-body -->

          <div class="modal-footer justify-content-center p-2">
          </div>  <!-- End modal-footer -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SucursalInfoModal",
  mounted() {
    // console.log(this.families);
  },
  props: {
    sucursal: Object,
    // families: Array,
  },
  computed: {
    hasSomeNetwork() {
      if (this.sucursal) {
        const some = Object.entries(this.sucursal.networks).some((v) => v[1]);

        console.log("some", some);
        return some;
      }

      return false;
    },
  },
  methods: {
    closeMenu: function () {
      this.$refs.closeMenu.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &-content {
    background: var(--secondary-bg);
    border: 0;
    img {
      width: 28px;
    }
  }
  &-body {
    button {
      background: var(--secondary-bg);
      color: var(--primary-font-cl);
    }
  }
  &-header {
    border: 0;
    button {
      border: 0;
      background: transparent;
      font-size: 2.8rem;
      color: var(--primary-font-cl);
    }
  }
  &-dialog {
    height: 100%;
    display: flex;
    align-items: flex-end;
    border-radius: 10px;
  }
  &-footer {
    border: 0;
  }
}
.accordion {
  &-button {
    font-size: $ft-xs;
    &:focus {
      border: 0;
      box-shadow: none;
    }
  }
  &-item {
    border-bottom: 1px solid var(--primary-font-cl);
    background: var(--secondary-bg);
  }
  &-body li {
    padding-bottom: 5px;
    a {
      color: var(--secondary-font-cl);
    }
  }
}
@media (min-width: $tablet-width) {
  #modalMenu .modal {
    &-dialog {
      width: 70%;
    }
    &-content {
      height: 50vh;
    }
  }
}
</style>